import React, { Suspense } from "react";

import { Routes, Route } from "react-router-dom";

import Home from "./Component/Pages/Home";
// import InviteCircle from './Component/Pages/InviteCircle';
//import Menu from './Component/Pages/Menu/Menu';
import MoreInsight from "./Component/Pages/MoreInsight";
// import QustionStep from './Component/Pages/QustionStep';
import logo from "./assets/Figma/loader.gif";
import PrivacyPolicy from "./Component/Pages/privacy-policy";
import TermSetting from "./Component/Pages/term-settings";
import Key from "./Component/Pages/Key";
import ReactGA from "react-ga4";

const GA_TRACKING_ID = process.env.REACT_APP_ENV === "development" ? "G-PG8MP0T7VJ" : "G-PNZSHKT4M0";
ReactGA.initialize(GA_TRACKING_ID);

const QustionStep = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./Component/Pages/QustionStep")), 2000);
  });
});

function App() {
  return (
    <>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-settings" element={<TermSetting />} />
        <Route
          path="/"
          element={
            <>
              <Suspense
                fallback={
                  <div className="">
                    <img
                      src={logo}
                      alt={"logo"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://admin.ownerscope.com/QuestionImage/WeClosed.jpg";
                      }}
                      className="text-center logoLoder"
                    />
                  </div>
                }
              >
                <QustionStep />
              </Suspense>{" "}
            </>
          }
        />
        <Route
          path="/MoreInsight"
          element={
            <>
              {" "}
              <MoreInsight />
            </>
          }
        />
        <Route path="/Key" element={<Key />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
